import React, { useRef, useState, useEffect } from 'react';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import { Box, Form } from 'react-bulma-components';

export const Player: React.FC<{ src: string }> = ({ src }) => {
  const minSpeed = 0.2;
  const maxSpeed = 8;

  const audioRef = useRef<HTMLAudioElement>(document.createElement('audio'));
  const [playbackRate, setPlaybackRate] = useState(1);

  useEffect(() => {
    audioRef.current.playbackRate = playbackRate;
  }, [playbackRate, src]);

  const handleChangePlaySpeed = (e: any) => {
    setPlaybackRate(e.target.value);
  };

  return (
    <Box>
      <audio id="abc" ref={audioRef} controls src={src} />
      <Form.Field>
        <Form.Label>
          再生スピード x {playbackRate} ({minSpeed} - {maxSpeed})
        </Form.Label>
        <Form.Control>
          <input
            onInput={handleChangePlaySpeed}
            type="range"
            value={playbackRate}
            min={minSpeed}
            max={maxSpeed}
            step="0.1"
          />
        </Form.Control>
      </Form.Field>
    </Box>
  );
};
