import React from 'react';
import './App.css';
import { TopPage } from './components/TopPage';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import { Footer, Content } from 'react-bulma-components';

function App() {
  return (
    <>
      <TopPage />
      <Footer>
        <Content style={{ textAlign: 'center' }}>
          <p>
            (C)2020 <a href="https://github.com/ynishi">ynishi</a>, All rights
            rserved.
          </p>
        </Content>
      </Footer>
    </>
  );
}

export default App;
