import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import {
  Section,
  Container,
  Heading,
  Button,
  Form,
  Icon,
  Content,
} from 'react-bulma-components';
import { Player } from './Player';

function reqOpts(body) {
  console.log(JSON.stringify(body));
  body.speechId = +body.speechId;
  body.audioLink = '';
  return {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
}

function reqOptsForm(form) {
  console.log(form);
  return {
    method: 'POST',
    body: form,
  };
}

type FormDataT = {
  speechId: number;
  contents: string;
  audioLink: string;
  langCode: string;
};

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
  ? process.env.REACT_APP_API_ENDPOINT
  : 'http://localhost:8080';

const REACT_APP_PUBLIC = process.env.REACT_APP_PUBLIC
  ? process.env.REACT_APP_PUBLIC
  : '';

export const TopPage: React.FC = () => {
  const { register, handleSubmit } = useForm<FormDataT>();
  const onSubmit = async (data) => {
    var opts: any;
    var api: string;
    if (fvalue) {
      let formData = new FormData();
      formData.append('langCode', data.langCode);
      formData.append('file', fvalue, fvalue.name);
      opts = reqOptsForm(formData);
      api = REACT_APP_API_ENDPOINT + '/upload';
    } else {
      opts = reqOpts(data);
      api = REACT_APP_API_ENDPOINT + '/speech';
    }
    console.log(opts);
    fetch(api, opts)
      .then((resp) => resp.json())
      .then((data) => setAValue(data.audioLink));
  };
  const [value, setValue] = useState<string>('');
  const [nvalue, setNValue] = useState<number>(0);
  const [avalue, setAValue] = useState<string>('');
  const [lcvalue, setLCValue] = useState<string>('ja-JP');
  const [fvalue, setFileValue] = useState<File>();

  const handleChange = (e: any) => {
    console.log(e.target.value);
    setValue(e.target.value);
  };
  const handleChangeN = (e: any) => {
    setNValue(e.target.value);
  };
  const handleChangeLC = (e: any) => {
    setLCValue(e.target.value);
  };
  const handleChangeFile = (e: any) => {
    setFileValue(e.target.files.item(0));
  };
  const getFileURL = () => {
    return REACT_APP_PUBLIC + avalue;
  };

  return (
    <>
      <Section>
        <Container>
          <Heading size={4}>sp2sp.com</Heading>
          <Heading size={6} subtitle renderAs="h2">
            シンプルなテキスト読み上げアプリ
          </Heading>
        </Container>
      </Section>
      <Section>
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              id="id"
              name="speechId"
              value={nvalue}
              type="number"
              onChange={handleChangeN}
              ref={register}
              hidden
            />
            <Form.Field>
              <Form.Label>読み上げテキスト</Form.Label>
              <Form.Control>
                <textarea
                  className="textarea"
                  id="text-area"
                  name="contents"
                  value={value}
                  onChange={handleChange}
                  ref={register}
                />
              </Form.Control>
            </Form.Field>
            <Form.Field>
              <Form.Label>
                読み上げファイル(※
                ファイルをアップロードした場合、テキストは処理されません)
              </Form.Label>
              <Form.Control>
                <Form.InputFile
                  icon={<Icon icon="upload" />}
                  boxed
                  placeholder="Textarea"
                  onChange={handleChangeFile}
                />
              </Form.Control>
            </Form.Field>
            <Form.Field>
              <Form.Label>読み上げ言語</Form.Label>
              <Form.Control>
                <label className="radio">
                  <input
                    type="radio"
                    onChange={handleChangeLC}
                    checked={lcvalue === 'ja-JP'}
                    value="ja-JP"
                    name="langCode"
                    ref={register}
                  />
                  日本語
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    onChange={handleChangeLC}
                    checked={lcvalue === 'en-US'}
                    value="en-US"
                    name="langCode"
                    ref={register}
                  />
                  英語(US)
                </label>
              </Form.Control>
            </Form.Field>
            <Button color="primary">変換</Button>
          </form>
        </Container>
      </Section>
      <Section>
        <Container>
          <Player src={getFileURL()} />
          <Form.Field>
            <Form.Label>
              ファイルリンク(※リンクを知ってる人は誰でも操作ができます)
            </Form.Label>
            <Form.Control>
              <input
                className="input"
                id="link"
                name="audioLink"
                value={getFileURL()}
                readOnly
              />
            </Form.Control>
          </Form.Field>
        </Container>
      </Section>
      <Section>
        <Container>
          <Content>
            <h3>使い方</h3>
            <ol>
              <li>読み上げしたいテキストをテキストエリアに貼り付けます。</li>
              <li>変換をクリックします。</li>
              <li>
                変換が完了したらリンクが更新され、プレイヤーがアクティブになります。
              </li>
              <li>プレイヤーで読み上げテキストを聞くことができます。</li>
              <li>
                またリンクを保存しておくことで、後から聞くことができます。
              </li>
            </ol>
            <h3>料金</h3>
            <p>本サービスは全て無料で使用できます。</p>
            <h3>ご注意</h3>
            <ul>
              <li>
                本アプリは、使用者が使用権を持つテキストを音声ファイルに変換するためのものです。
              </li>
              <li>
                テキストの著作権等の各種権利はテキストの著作権者に帰属します。その許諾範囲に従った使用をお願いします。
              </li>
              <li>
                ファイルリンク機能も同様です。リンクアドレスの公開、配布等が可能な範囲はテキストの許諾内容に準じます。
              </li>
              <li>
                上記、音声ファイルの使用(許諾範囲を超える使用による影響等含む)責任は全て使用者に帰属し、本アプリ及び運営者は一切責任を負いません。
              </li>
              <li>
                不正な使用等が発覚もしくは著作権者等による指摘があった場合、予告なく該当データを削除があります。
              </li>
              <li>
                本アプリは、一切のデータ保持、サービス提供時間等を担保いたしません。本サービスの停止等による損害には一切責任を追いません。
              </li>
              <li>
                本アプリは、サービス内容を予告なく変更する場合があります。それらの変更内容は速やかに本ページに反映されます。
              </li>
            </ul>
          </Content>
        </Container>
      </Section>
    </>
  );
};
